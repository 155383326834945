import bg from "../assets/bg.png";
import chef from "../assets/chef.png";
import G from "../assets/G.png";
import gallery01 from "../assets/gallery01.png";
import gallery02 from "../assets/gallery02.png";
import gallery03 from "../assets/gallery03.png";
import gallery04 from "../assets/gallery04.png";
import knife from "../assets/knife.png";
import logo from "../assets/logo.png";
import menu from "../assets/menu.png";
import overlaybg from "../assets/overlaybg.png";
import spoon from "../assets/spoon.svg";
import welcome from "../assets/welcome.png";
import findus from "../assets/findus.png";
import laurels from "../assets/laurels.png";
import award01 from "../assets/award01.png";
import award02 from "../assets/award02.png";
import award03 from "../assets/award03.png";
import award05 from "../assets/award05.png";
import sign from "../assets/sign.png";
import quote from "../assets/quote.png";
import gericht from "../assets/gericht.png";
import sweets from "../assets/sweets-cloud.png";
import chocolate from "../assets/chocolate.png";
import offer01 from "../assets/offer01.png";
import ig_page from "../assets/instagram_page.png";
import fb_page from "../assets/facebook_page.png";

const images = {
  bg,
  chef,
  G,
  gallery01,
  gallery02,
  gallery03,
  gallery04,
  knife,
  logo,
  menu,
  overlaybg,
  spoon,
  welcome,
  findus,
  laurels,
  award01,
  award02,
  award03,
  award05,
  sign,
  quote,
  gericht,
  sweets,
  chocolate,
  offer01,
  ig_page,
  fb_page,
};

export default images;
